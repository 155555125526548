import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import '../../styles/about.sass'

const TeamPage = () => {
    return (<Layout>
        <SEO title="The Team"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <h1>Committee</h1>
                <center className="committee-tables">
                    <h4>2024 - 2025</h4>
                    <table style={{fontFamily: 'Source Code Pro'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dora B.</td>
                                <td>President</td>
                            </tr>
                            <tr>
                                <td>Bilal M.</td>
                                <td>VP Training + VP Compétitions</td>
                            </tr>
                            <tr>
                                <td>François M.</td>
                                <td>VP Commandites</td>
                            </tr>
                            <tr>
                                <td>Yoann S. M.</td>
                                <td>VP Comms</td>
                            </tr>
                            <tr>
                                <td>Corentin F.</td>
                                <td>VP Infra</td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
        </div>
    </Layout>)
}
export default TeamPage
